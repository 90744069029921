import React, { useEffect, useState } from 'react';
import { TailSpin } from "react-loader-spinner";
import dayjs from 'dayjs'

import GeneralLayout from '../../layouts/GeneralLayout';
import arrowleft from '../../assets/arrowleft.svg'
import arrowright from '../../assets/arrowright.svg'
import { footballApi, useGetAllArticlesByAdminQuery, useGetUserQuery } from '../../redux/services/Football';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';




const ArticleRead =  () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const headers = ['Match details', 'Author', 'Reads'];
    const [ currentPage, setCurrentPage ] = useState(1);
    const { isLoading, isError, error } = useGetUserQuery("", { skip: !localStorage.getItem("token") });
    const { isFetching, data: adminArticleData } = useGetAllArticlesByAdminQuery(currentPage);

    useEffect(() => {
        if(isError){
            if(error?.data?.error==="Token has expired"){
              dispatch(footballApi?.util?.resetApiState());
              localStorage.removeItem("token");
              navigate("/auth/article/login")
            }
        }else if(!localStorage.getItem("token") || isError ){
          navigate("/")
        }
      }, [navigate, isError, error, dispatch])

    const previousPage = () => {
        if (currentPage !== 1) {
           setCurrentPage(currentPage - 1);
        }
     };

     const nextPage = () => {
        if (currentPage !== Math.ceil(adminArticleData.pagination.total /adminArticleData.pagination.limit)) {
           setCurrentPage(currentPage + 1);
        }
     };

    return (
        <GeneralLayout>
        <div className='text-center md:p-0 p-0 max-w-[850px] mx-auto' >
            <h1 className='font-bitter md:text-[40px] text-[28px] md:leading-[48px] leading-[32px] font-bold center md:my-[64px] my-[28px]' >Article Reads</h1>
        </div>
        {(isFetching || isLoading) ? <div className='flex justify-center items-center h-[40vh]' ><TailSpin
          height="80"
          width="80"
          color="#000A0F"
        /></div> : <div className='pt-0 mt-0 mb-[28px] md:mb-[48px] md:rounded-[8px] md:w-5/6 mx-auto md:border md:shadow-md' >
            <div className='w-full mx-auto max-w-[1440px] item-center overflow-x-auto '>
                <table className='w-full border-collapse border-none overflow-x-auto ' >
                    <thead className='bg-[#F9FBFC] border-none h-[37px] md:h-[44px]' >
                        <tr className='border-b border-[#F1F3F4]' >
                            {headers?.map(header => <th key={header} className={`border-none text-[#557586] py-[12px] px-[24px] text-[14px] md:text-[16px] md:leading-[24px] font-semibold text-left`}>
                                {header}
                            </th>)}
                        </tr>
                    </thead>
                    <tbody>
                        {adminArticleData?.articles?.map((item) => <tr key={item?._id} className='border-b border-[#F1F3F4] text-[14px] leading-[21px]'> 
                            <td className='text-left py-[16px] px-[24px]'>
                                <div className='text-[#4E575C] mb-[9.5px]'>
                                    <span>{item.title}</span>
                                    {/* <img src={} alt="Team Logo" className='max-h-[21px] max-w-[32px] inline-block align-middle' /> */}
                                </div>
                                <span className='text-[#6A767D]'>{dayjs(item?.publishedAt).format("DD MMMM, YYYY")}</span>
                            </td>
                            <td className='text-left py-[16px] px-[24px] text-[16px] text-[#4E575C]'>{item?.author}</td>
                            <td className='text-left py-[16px] px-[24px] text-[18px] font-semibold text-[#003049]'>{item?.views}</td>
                        </tr>)}
                    </tbody>
                </table>
            </div>
            <div className='flex justify-between border-b border-[#F1F3F4] py-[12px] px-[24px]'>
                <div onClick={previousPage} className={`flex justify-center items-center border rounded-[8px] py-[8px] px-[14px] shadow-md ${currentPage !== 1 ? "cursor-pointer":"cursor-default"}`}>
                    <img src={arrowleft} alt="arrowleft" height={20} width={20} /><span className='pl-[8px]'>Previous</span>
                </div>
                <div onClick={nextPage} className={`flex justify-center items-center border rounded-[8px] py-[8px] px-[14px] shadow-md ${currentPage !== Math.ceil(adminArticleData.pagination.total /adminArticleData.pagination.limit)? "cursor-pointer":"cursor-default"}`}>
                    <span className='pr-[8px]'>Next</span><img src={arrowright} alt="arrowright" height={20} width={20} />
                </div>
            </div>
        </div>}

        </GeneralLayout>
    )
    
    
}

export default ArticleRead;
