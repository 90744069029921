import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Logo from "../../assets/Logo.svg"
import MobileLogo from "../../assets/MobileLogo.svg"
import Vector from "../../assets/Vector.svg"
import Frame from "../../assets/Frame.svg"
import Cancel from "../../assets/Cancel.svg"
import Search from "../../assets/Search.svg"
import SearchBg from "../../assets/SearchBg.svg"
import { footballApi, useGetUserQuery } from '../../redux/services/Football';
import { useDispatch } from 'react-redux';
import { MyContext } from '../../layouts/GeneralLayout';
import useOnClickOutside from '../../hooks/useOnClickOutside';

const NavBar = () => {
    const navTabs = ["Premier League", "Championship ", "League One", "European Matches", "Friendlies"]
    const { page, setPage } = useContext(MyContext);
    const [dropDown, setDropDown] = useState(false);
    const ref = useRef();
    const dispatch = useDispatch();
    const location = useLocation();
    const { isLoading, isError, error, isSuccess } = useGetUserQuery("", { skip: (!localStorage.getItem("token") || location.pathname.includes("login") || location.pathname.includes("resetpassword") ) });
    const navigate = useNavigate();
    const [ openNav, setOpenNav ] = useState(false);

    useOnClickOutside(ref, () => {
        dropDown && setDropDown(false);
    });

    const handleButtonClick = () => {
        const element = document.getElementById('subscribecontainer');
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
          openNav &&setOpenNav(false);
        }
    };

    const handlePage = (route) => {
        if(!location.pathname.includes(route)){
            handleSetPage()
        }
    }

    const handleSetPage = () => {
        if(page > 1){
            setPage(1)
        }
    }

    const customPage = () => {
        handleSetPage()
        setDropDown(val => !val)
        navigate(`/create-custom/admin/article`);
    }

    const articleReads = () => {
        handleSetPage()
        setDropDown(val => !val)
        navigate(`/admin/articleread`);
    }

    useEffect(() => {
        if(isError){
            if(error?.data?.error==="Token has expired"){
              dispatch(footballApi?.util?.resetApiState());
              localStorage.removeItem("token");
              navigate("/auth/article/login")
            }
        }
      }, [navigate, isError, error, dispatch])
  return (
    <>
        <nav className={` ${openNav && " md:flex"} sticky z-[50] top-0 flex bg-[#FDFDFD] w-full h-[68px] md:h-[120px] p-5 md:px-10 md:py-7 items-center justify-between`} >
            <div onClick={()=> handleSetPage()} className='' >
                <Link to="/" >
                    <img className='w-[170px] h-[62px] md:block hidden' src={Logo} alt="Comapny logo" />
                    <img className='w-[100px] h-[36px] block md:hidden' src={MobileLogo} alt="Comapny logo" />
                </Link>
            </div>
            <div className='md:mx-auto hidden md:block' >
                <ul className="flex md:flex-row flex-col list-none">
                    {navTabs?.map(item => <li onClick={()=> handlePage(item)} className='text-[18px] text-[#000A0F] font-medium leading-[24px] ml-2 smallLaptop:ml-[24px] hover:text-[#1D5775]' key={item} ><Link to={`/league/${item}`} >{item}</Link></li>)}
                </ul>
            </div>
            <div className='ml-auto flex items-center' >
                <Link onClick={()=> handleSetPage()} to="/search" ><div className='rounded-full hover:bg-[#003049] bg-[#F1F3F4] h-[32px] w-[32px] md:h-[48px] md:w-[48px] flex justify-center items-center cursor-pointer group' >
                    <img src={Search} alt="search" className="group-hover:hidden md:h-[24px] md:w-[24px] h-[18px] w-[18px] " />
                    <img src={SearchBg} alt="search" className="hidden group-hover:block md:h-[24px] md:w-[24px] h-[18px] w-[18px] " />
                </div></Link>
                <button onClick={handleButtonClick} className='md:ml-5 hidden verySmallLaptop:block text-white bg-[#F77F00] hover:text-[#F77F00] hover:border hover:border-[#F77F00] hover:bg-transparent font-semibold text-[16px] leading-[24px] rounded-[4px] h-[48px] w-[160px]' >Subscribe for free</button>
                {isSuccess && !isLoading && <div ref={ref} className="mx-3 bg-[#F1F3F4] rounded-[4px] h-[48px] relative text-[#003049] cursor-pointer" >
                    <span onClick={() => setDropDown(val => !val)} className='flex justify-center items-center gap-x-1 h-full px-4 py-3.5 w-full' >
                        <p className='font-semibold text-[16px] leading-[150%]' >Admin features</p>
                        <img className='w-[24px] h-[24px] md:block hidden' src={Frame} alt="dropdown" />
                    </span>
                    <div className={`${dropDown ? "block" : "hidden"} h-[108px] w-[180px] absolute z-[1] bg-[#F1F3F4] rounded-[4px] right-0 top-[56px]`} >
                        <div className='w-full h-full flex flex-col gap-y-1' >
                            <div onClick={customPage} className='flex items-center justify-center px-4 py-3.5 text-[#003049] rounded-[4px] hover:bg-[#003049] hover:text-[#F9FBFC]' >
                                <p className='font-semibold text-[16px] leading-[150%]' >Custom article</p>
                            </div>
                            <div onClick={articleReads} className='flex items-center justify-center px-4 py-3.5 text-[#003049] rounded-[4px] hover:bg-[#003049] hover:text-[#F9FBFC]' >
                                <p className='font-semibold text-[16px] leading-[150%]' >Article reads</p>
                            </div>
                        </div>
                    </div>
                </div>}
                {/* {isSuccess && !isLoading && <button onClick={()=>{
                        handleSetPage()
                        navigate(`/create-custom/admin/article`)
                    }} className='hidden verySmallLaptop:block text-white bg-[#003049] font-semibold text-[16px] leading-[24px] rounded-[4px] h-[48px] w-[160px] hover:bg-[#1D5775] mx-3' >Custom Article</button>} */}
            </div>
            <div className='mr-3 ml-5 md:hidden block' >
                <img onClick={()=> setOpenNav(true)} className='w-[18px] h-[16px]' src={Vector} alt="Comapny logo" />
            </div>
        </nav>
        <nav className={`bg-[#FDFDFD] w-full h-screen fixed top-0 left-0 z-[55] p-5 ${openNav ? "block" : "hidden"} md:hidden`} >
            <div className='flex w-full' >
                <div className='ml-auto' >
                    <img onClick={()=> setOpenNav(false)} className='w-[24px] h-[24px] cursor-pointer' src={Cancel} alt="close-nav" />
                </div>
            </div>
            <ul className="flex md:flex-row flex-col list-none">
                {navTabs?.map(item => <li onClick={()=> {
                    handlePage(item)
                    setOpenNav(false)
                    }} className='text-[17px] text-[#000A0F] font-medium leading-[24px] my-3' key={item} ><Link to={`/league/${item}`} >{item}</Link></li>)}
            </ul>
            <button onClick={handleButtonClick} className='mt-3 text-white bg-[#F77F00] font-semibold text-[16px] leading-[24px] rounded-[4px] h-[52px] w-[170px]' >Subscribe for free</button>
            {isSuccess && !isLoading && <div>
                <button onClick={()=>{
                        handleSetPage()
                        navigate(`/create-custom/admin/article`)
                    }} className='text-white bg-[#003049] font-semibold text-[16px] leading-[24px] rounded-[4px] h-[52px] w-[170px] hover:bg-[#1D5775] mt-6' >Custom Article</button>
            </div>}
        </nav>
    </>
  )
}

export default NavBar