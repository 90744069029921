import React, { useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ArticleItem from '../../components/ArticleItem/ArticleItem';
import RelatedPreviews from '../../components/ArticleItem/RelatedPreviews'
import GeneralLayout from '../../layouts/GeneralLayout'
import { footballApi, useGetArticleByIdQuery, useGetRelatedArticlesQuery, useGetUserQuery } from '../../redux/services/Football';
import { TailSpin } from "react-loader-spinner";
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';

const Article = ({admin}) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLoading, data, isSuccess: isArticleSuccess } = useGetArticleByIdQuery(id)
  const { isSuccess, isLoading: isUserLoading, isError: isUserError, error: userError } = useGetUserQuery("", { skip: !localStorage.getItem("token") });
  
  const articleData = useMemo(() => data?.articles?.[0], [data?.articles])
  const { isLoading: isLoadingRelated, data: relatedData } = useGetRelatedArticlesQuery({id: articleData?.league, page: 1, limit: 10}, { skip: !(!isLoading && isArticleSuccess) });
  const relatedArticleData = useMemo(() => relatedData?.articles, [relatedData?.articles])
  
  // useEffect(() => {
  //   if(admin && isSuccess && (data?.message=== "Article not found" || data?.message=== "Article is saved") ){
  //     navigate(`/article/create/admin/${id}`)
  //   }
  // }, [admin, isSuccess, navigate, id, data?.message])

  const handleCreate = () => {
    navigate(`/article/create/admin/${id}`)
  }

  useEffect(() => {
    if(isUserError){
        if(userError?.data?.error==="Token has expired"){
          dispatch(footballApi?.util?.resetApiState());
          localStorage.removeItem("token");
          navigate("/auth/article/login")
        }
    }
  }, [isUserError, userError, navigate, dispatch]);
  
  return (
    <div>
        <GeneralLayout >
          {(isLoading || isUserLoading) ? <div className='flex justify-center items-center h-[80vh]' ><TailSpin
          height="80"
          width="80"
          color="#000A0F"
        /></div> : (data?.message=== "Article not found" || data?.message=== "Article is saved") ? <div className='h-[80%] flex items-center justify-center ' >
              <div className='w-[600px] rounded-[20px] h-[500px] mx-3 p-5' >
                <div className='flex justify-center items-center h-full' >
                    <div>
                        {(admin && isSuccess && (data?.message=== "Article not found" || data?.message=== "Article is saved")) && <p onClick={handleCreate} className="cursor-pointer text-center" >Create Preview for this fixture</p>}
                        <p className='font-semibold text-[24px] md:text-[32px]' >{"No preview for this fixture"}</p>
                    </div>
                </div>
              </div>
            </div> : 
            <>
              <Helmet>
                <title>{articleData.title}</title>
                <meta name="description" content={articleData.description} />

                {/* Open Graph / Facebook */}
                <meta property="og:type" content="website" />
                <meta property="og:title" content={articleData.title} />
                <meta property="og:description" content={articleData.description} />
                <meta property="og:image" content={articleData.image} />
                <meta property="og:url" content={window.location.href} />

                {/* Twitter */}
                <meta property="twitter:title" content={articleData.title} />
                <meta property="twitter:description" content={articleData.description} />
                <meta property="twitter:image" content={articleData.image} />
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content={window.location.href} />
              </Helmet>
              <ArticleItem articleData={articleData} isSuccess={isSuccess} />
              {isLoadingRelated ? <div className='flex justify-center items-center h-[40vh]' ><TailSpin
                height="80"
                width="80"
                color="#000A0F"
              /></div> : <RelatedPreviews isSuccess={isSuccess} id={id} relatedArticleData={relatedArticleData} />}
              </>}
        </GeneralLayout>
    </div>
  )
}

export default Article