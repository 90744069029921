import { useFormik } from 'formik';
import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { EmailValidations, ResetPasswordValidations } from '../../helpers/validations';
import GeneralLayout from '../../layouts/GeneralLayout'
import { TailSpin } from "react-loader-spinner";
import { useForgotPasswordMutation, useResetPasswordMutation } from '../../redux/services/Football';
import { toast } from "react-toastify";

const ResetPassword = () => {
    const navigate = useNavigate();
    const [ forgotPassword, { isLoading, isSuccess } ] = useForgotPasswordMutation();
    const [ resetPassword, { isLoading: isResetLoading, isSuccess: isResetSuccess } ] = useResetPasswordMutation();

    useEffect(() => {
        if(isSuccess){
            toast.success("OTP sent successfully", {
                position: toast.POSITION.TOP_CENTER
            })
        }
    }, [isSuccess])

    useEffect(() => {
        if(isResetSuccess){
            toast.success("Password reset successful", {
                position: toast.POSITION.TOP_CENTER
            })
            navigate("/auth/article/login")
        }
    }, [isResetSuccess, navigate])

    const formik = useFormik({
        initialValues: {
          email: '',
          password: '',
          confirmPassword: '',
          otp: ""
        },
        validationSchema: isSuccess ? ResetPasswordValidations : EmailValidations,
        onSubmit: async values => {
            isSuccess ? resetPassword({ password: values.password, confirmPassword: values.confirmPassword, otp: values.otp }) : forgotPassword({ email: values.email });
        }
      });
    const {
        values,
        handleChange,
        handleSubmit,
        handleBlur,
        errors,
        touched,
    } = formik;
  return (
    <div>
        <GeneralLayout>
            <div className='flex justify-center items-center h-[80vh] bg-[#FDFDFD] w-full' >
                <div className='flex flex-col w-full max-w-[450px] md:px-10 px-5 bg-white rounded-[10px] shadow-[0_15px_30px_40px_rgba(0,0,0,0.04)]' >
                    <p className={`text-center md:text-[24px] text-[18px] font-bold my-5`} >Reset Password</p>
                    {
                        isSuccess ?
                        <>
                            <p>Password</p>
                            <input label="Password" placeholder="Enter your password" name="password" value={values.password} onChange={handleChange("password")} onBlur={handleBlur("password")} error={errors.password} type="password" className={`w-full ${errors.password ? "mt-0" : "mb-5"} outline-none border border-[#727272] rounded-[5px] h-[50px] p-5`} />
                            {touched.password && errors.password && <div className="text-red-500 text-[12px] mb-5">{errors.password}</div>}
                            <p>Confirm Password</p>
                            <input label="confirmPassword" placeholder="Enter your password" name="confirmPassword" value={values.confirmPassword} onChange={handleChange("confirmPassword")} onBlur={handleBlur("confirmPassword")} error={errors.confirmPassword} type="password" className={`w-full ${errors.confirmPassword ? "mt-0" : "mb-5"} outline-none border border-[#727272] rounded-[5px] h-[50px] p-5`} />
                            {touched.confirmPassword && errors.confirmPassword && <div className="text-red-500 text-[12px] mb-5">{errors.confirmPassword}</div>}
                            <p>OTP</p>
                            <input label="OTP" placeholder="Enter your otp" name="otp" value={values.otp} onChange={handleChange("otp")} onBlur={handleBlur("otp")} error={errors.otp} type="text" className={`w-full ${errors.otp ? "mt-0" : "mb-5"} outline-none border border-[#727272] rounded-[5px] h-[50px] p-5`} />
                            {touched.otp && errors.otp && <div className="text-red-500 text-[12px] mb-5">{errors.otp}</div>}
                        </>
                        :
                        <>
                            <input label="Email" placeholder="Enter your email" name="email" value={values.email} onChange={handleChange("email")} onBlur={handleBlur("email")} error={errors.email} touched={touched.email} type="email" className={`w-full ${errors.email ? "mt-5" : "my-5"} outline-none border border-[#727272] rounded-[5px] h-[50px] p-5`} />
                            {touched.email && errors.email && <div className="text-red-500 text-[12px] mb-5">{errors.email}</div>}
                        </>
                    }
                    <button type="submit" disabled={isLoading || isResetLoading} onClick={handleSubmit} className='text-white bg-[#003049] font-semibold text-[16px] leading-[24px] rounded-[4px] w-full h-[48px] hover:bg-[#1D5775] mt-5 mb-3 flex justify-center items-center' >{(isLoading || isResetLoading) ? <div data-testid="loader2" className="w-6 m-a">
                        <TailSpin color={"#fff"} height="21" width="21" />
                    </div> : "Continue"}</button>
                    {!isSuccess && <div className='mb-10 flex justify-end' >
                      <p className='text-[14px] underline ' ><Link to="/auth/article/login" >Login</Link></p>
                    </div>}
                </div>
            </div>
        </GeneralLayout>
    </div>
  )
}

export default ResetPassword