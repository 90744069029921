import React from 'react'
// import NavBar from '../../components/NavBar/NavBar'
import GeneralLayout from '../../layouts/GeneralLayout'

const NotFound = () => {
  return (
    <GeneralLayout >
        {/* <NavBar /> */}
        <div className='h-[80%] flex items-center justify-center ' >
          <div className='w-[600px]  rounded-[20px] h-[500px] mx-3 p-5' >
            <div className='flex justify-center items-center h-full' >
                <p className='font-semibold text-[24px] md:text-[32px]' >Page not found</p>
            </div>
          </div>
        </div>
    </GeneralLayout>
  )
}

export default NotFound