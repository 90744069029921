import { Editor } from '@tinymce/tinymce-react';
import { useFormik } from 'formik';
import React, { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router';
import { ArticleValidations, FixtureValidations } from '../../helpers/validations';
import GeneralLayout from '../../layouts/GeneralLayout';
import { TailSpin } from "react-loader-spinner";
import { footballApi, useGetFixtureByIdQuery, useGetUserQuery, useUpdateArticleMutation, useUpdateFixtureMutation } from '../../redux/services/Football';
import { toast } from "react-toastify";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import TextField from '@mui/material/TextField';
import dayjs from 'dayjs';
import { postApi } from '../../utils/reqClient';
import Modal from '../../components/Modal/Modal';
import ArticleItem from '../../components/ArticleItem/ArticleItem';
import Cancel from "../../assets/Cancel.svg"

const EditCustom = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [preview, setPreview] = React.useState(false);
  const { isLoading: isFixtureLoading, data: fixturesData } = useGetFixtureByIdQuery(id);
  const fixtureData = useMemo(() => fixturesData?.fixtures, [fixturesData?.fixtures]);
  const { isLoading, isError, error } = useGetUserQuery("", { skip: !localStorage.getItem("token") });
  const [ updateArticle, { isLoading: isPostLoading, isSuccess } ] = useUpdateArticleMutation();
  const [ updateFixture, { isLoading: isPostFixtureLoading, isSuccess: isPostFixtureSuccess, data: fixtureSuccessData } ] = useUpdateFixtureMutation();
  const successFixtureData = useMemo(() => fixtureSuccessData?.fixtures, [fixtureSuccessData?.fixtures])
  const [dragActive, setDragActive] = React.useState(false);
  const [fixtureUpdate, setFixtureUpdate] = React.useState(false);
  const formik = useFormik({
    initialValues: {
      description: state?.edit ? state?.articleData?.description : '',
      body: state?.edit ? state?.articleData?.body : '',
      image: state?.edit ? state?.articleData?.image : "",
      imageSource: state?.edit ? state?.articleData?.imageSource : "",
      topArticle: state?.edit ? state?.articleData?.topArticle : false,
      homeTeam: "",
      awayTeam: "",
      dateAndTime: "",
      homeTeamLogo: null,
      awayTeamLogo: null,
      league: ""
    },
    validationSchema: fixtureUpdate ? ArticleValidations : FixtureValidations,
    onSubmit: async values => {
        let formdata = new FormData();
        let fixtureFormData = new FormData();
        if(fixtureUpdate){
          formdata.append("description", values.description);
          formdata.append("topArticle", values.topArticle);
          formdata.append("body", values.body);
          formdata.append("imageSource", values.imageSource);
          formdata.append("league", `${fixtureData?.league?.name}`);
          formdata.append("image", values.image?.[0]?.name ? values.image?.[0] : values.image);
          formdata.append("title", `${fixtureData?.teams?.home?.name} vs ${fixtureData?.teams?.away?.name}`);
          formdata.append("tags", `${fixtureData?.league?.name}`);
          formdata.append("tags", `${fixtureData?.teams?.home?.name}`);
          formdata.append("tags", `${fixtureData?.teams?.away?.name}`);
          formdata.append("custom", true);
          updateArticle({ id: state?.articleData?._id, formdata });
        }else {
          fixtureFormData.append("homeTeam", values.homeTeam);
          fixtureFormData.append("awayTeam", values.awayTeam);
          fixtureFormData.append("dateAndTime", values.dateAndTime);
          fixtureFormData.append("league", values.league);
          fixtureFormData.append("homeLogo", values.homeTeamLogo?.[0]?.name ? values.homeTeamLogo?.[0] : values.homeTeamLogo);
          fixtureFormData.append("awayLogo", values.awayTeamLogo?.[0]?.name ? values.awayTeamLogo?.[0] : values.awayTeamLogo);
          updateFixture({ id: fixtureData?._id, formdata: fixtureFormData });
        }
    }
  });

  useEffect(() => {
    if((fixtureData && fixturesData?.success)){
      formik.setFieldValue("homeTeam", fixtureData?.teams?.home?.name);
      formik.setFieldValue("awayTeam", fixtureData?.teams?.away?.name);
      formik.setFieldValue("dateAndTime", dayjs(fixtureData?.fixture?.date));
      formik.setFieldValue("league", fixtureData?.league?.name);
      formik.setFieldValue("homeTeamLogo", fixtureData?.teams?.home?.logo);
      formik.setFieldValue("awayTeamLogo", fixtureData?.teams?.away?.logo);
    }
  }, [fixtureData]);

  useEffect(() => {
    if(isError){
        if(error?.data?.error==="Token has expired"){
          dispatch(footballApi?.util?.resetApiState());
          localStorage.removeItem("token");
          navigate("/auth/article/login")
        }
    }else if(!localStorage.getItem("token") || isError ){
      navigate("/")
    }
  }, [navigate, isError, error, dispatch])

  useEffect(() => {
    const unloadCallback = (event) => {
      if (!isSuccess) {
        event.preventDefault();
        event.returnValue = "";
        return "";
      }
    };
  
    window.addEventListener("beforeunload", unloadCallback);
  
    return () => window.removeEventListener("beforeunload", unloadCallback);
  }, [isSuccess]);

  useEffect(() => {
    if((isSuccess)){ 
      toast.success("Article Updated Successfully", {
          position: toast.POSITION.TOP_CENTER
      })
      window.location.href=`${process.env.PUBLIC_URL}/article/admin/${fixtureData?.fixture?.id}/${fixtureData?.teams?.home?.name} Vs ${fixtureData?.teams?.away?.name}`
    }
  },
  [isSuccess, navigate, fixtureData?.fixture?.id])

  useEffect(() => {
    if(isPostFixtureSuccess){
        toast.success("Fixture updated Successfully", {
            position: toast.POSITION.TOP_CENTER
        })
    }
  }, [isPostFixtureSuccess])
  

  const {
    values,
    handleChange,
    handleSubmit,
    setFieldValue,
    handleBlur,
    errors,
  } = formik;

  // handle drag events
  const handleDrag = function(e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };
  
  // triggers when file is dropped
  const handleDrop = function(e, name) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setFieldValue(name, e.dataTransfer.files)
      // handleFiles();
    }
  };

  return (
    <GeneralLayout >
        {(isFixtureLoading || isLoading) ? <div className='flex justify-center items-center h-[60vh]' ><TailSpin
                height="80"
                width="80"
                color="#000A0F"
                /></div> : <div className='md:p-10 p-5 max-w-[850px] mx-auto' >
            {isPostFixtureSuccess ? <h1 className='py-2 font-bold md:text-[3rem] text-[1.5rem] md:leading-[120%] leading-[120%] text-[#000A0F] md:text-[#000A0F]'>{`${successFixtureData?.teams?.home?.name} vs ${successFixtureData?.teams?.away?.name}`}</h1> : <div>
                <h1 className='md:text-[40px] text-[28px] md:leading-[48px] leading-[32px] font-bold' >Fixture</h1>
                <div className='flex flex-col gap-y-6 w-full pt-6 pb-8' >
                    <div className='flex md:flex-row flex-col gap-y-6 gap-x-5 w-full md:items-center' >
                        <div className='md:w-2/3 w-full' >
                            {/* Description */}
                            <p className={``} >Home team</p>
                            <input label="Home team" placeholder="Home team" name="homeTeam" value={values.homeTeam} onChange={handleChange("homeTeam")} onBlur={handleBlur("homeTeam")} type="text" className={`w-full  outline-none border border-[#727272] rounded-[5px] h-[50px] p-5`} />
                            {errors.homeTeam && <div className="text-red-500 text-[12px] mt-2">{errors.homeTeam}</div>}
                        </div>
                        <div className='md:w-1/3 w-full' >
                            {/* Description */}
                            {<>
                              <label className="" htmlFor="uploadHomeLogo" >
                                  <p className={``} >Home team logo</p>
                                  <div onDragEnter={handleDrag} className={`relative w-full  text-truncate h-[50px] cursor-pointer ${dragActive ? "border-dashed border" : "border"} rounded-[8px] border-[#727272] flex items-center`} >
                                      <p className='p-2 text-left text-[#727272] w-full truncate md:max-w-[200px]' >{values.homeTeamLogo?.length>0 ? values.homeTeamLogo?.[0]?.name ? values.homeTeamLogo?.[0]?.name : values.homeTeamLogo : "Upload image here"}</p>
                                      { dragActive && <div className='absolute w-full h-full top-0 left-0 bottom-0 right-0' onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={(e)=>handleDrop(e, "homeTeamLogo")}></div> }
                                  </div>
                              </label>
                              <input
                                  id="uploadHomeLogo"
                                  type="file"
                                  accept="image/*"
                                  className="overflow-hidden hidden"
                                  onChange={(e)=> setFieldValue("homeTeamLogo", e.target.files)}
                              />
                              {errors.homeTeamLogo && <div className="text-red-500 text-[12px] my-2">{errors.homeTeamLogo}</div>}
                            </>}
                        </div>
                    </div>
                    <div className='flex md:flex-row flex-col gap-y-6 gap-x-5 w-full md:items-center' >
                        <div className='md:w-2/3 w-full' >
                            {/* Description */}
                            <p className={``} >Away team</p>
                            <input label="Away team" placeholder="Away team" name="awayTeam" value={values.awayTeam} onChange={handleChange("awayTeam")} onBlur={handleBlur("awayTeam")} type="text" className={`w-full  outline-none border border-[#727272] rounded-[5px] h-[50px] p-5`} />
                            {errors.awayTeam && <div className="text-red-500 text-[12px] mt-2">{errors.awayTeam}</div>}
                        </div>
                        <div className='md:w-1/3 w-full' >
                            {/* Description */}
                            {<>
                              <label className="" htmlFor="uploadAwayLogo" >
                                  <p className={``} >Away team logo</p>
                                  <div onDragEnter={handleDrag} className={`relative w-full  text-truncate h-[50px] cursor-pointer ${dragActive ? "border-dashed border" : "border"} rounded-[8px] border-[#727272] flex items-center`} >
                                      <p className='p-2 text-[#727272] w-full truncate text-left md:max-w-[200px]' >{values.awayTeamLogo?.length>0 ? values.awayTeamLogo?.[0]?.name ? values.awayTeamLogo?.[0]?.name : values.awayTeamLogo : "Upload image here"}</p>
                                      { dragActive && <div className='absolute w-full h-full top-0 left-0 bottom-0 right-0' onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={(e)=>handleDrop(e, "awayTeamLogo")}></div> }
                                  </div>
                              </label>
                              <input
                                  id="uploadAwayLogo"
                                  type="file"
                                  accept="image/*"
                                  className="overflow-hidden hidden"
                                  onChange={(e)=> setFieldValue("awayTeamLogo", e.target.files)}
                              />
                              {errors.awayTeamLogo && <div className="text-red-500 text-[12px] mt-2">{errors.awayTeamLogo}</div>}
                            </>}
                        </div>
                    </div>
                    <div className='flex md:flex-row flex-col gap-y-6 gap-x-5 w-full ' >
                        <div className='md:w-2/3 w-full' >
                            {/* Description */}
                            <p className={``} >Date and Time(UK)</p>
                            <div className='h-[50px] w-full' >
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                  <DateTimePicker
                                      minDateTime={dayjs()}
                                      renderInput={(props) => <TextField placeholder="1 - Jan - 2022 at 8:00PM" fullWidth={true} {...props} />}
                                      value={values.dateAndTime}
                                      onChange={(newValue) => {
                                          formik.setFieldValue("dateAndTime", newValue);
                                      }}
                                  />
                              </LocalizationProvider>
                            </div>
                            {errors.dateAndTime && <div className="text-red-500 text-[12px] mt-2">{errors.dateAndTime}</div>}
                        </div>
                        <div className='md:w-1/3 w-full' >
                            <p className={``} >Fixture type</p>
                            <select value={values?.league} onChange={handleChange("league")} name="league" className='w-full outline-none border border-[#727272] rounded-[5px] h-[50px] p-2' >
                              <option value={``}>Select fixture type </option>
                              <option value={`European Matches`}>European Matches</option>
                              <option value={"Friendlies"}>Friendlies</option>
                            </select>
                            {errors.league && <div className="text-red-500 text-[12px] mt-2">{errors.league}</div>}
                        </div>
                    </div>
                    {/* Button */}
                    <button onClick={()=>{
                            setFixtureUpdate(false)
                            handleSubmit()
                        }} disabled={isPostFixtureLoading} className='text-white bg-[#003049] font-semibold text-[16px] leading-[24px] rounded-[4px] w-full h-[50px] hover:bg-[#1D5775] flex justify-center items-center' type="submit">{(isPostFixtureLoading) ? <div data-testid="loader2" className="w-6 m-a">
                        <TailSpin color={"#fff"} height="21" width="21" />
                    </div> : "Update Fixture"}</button>
                </div>
            </div>}
            <div>
                {!isPostFixtureSuccess && <h1 className='md:text-[40px] text-[28px] md:leading-[48px] leading-[32px] font-bold' >Article</h1>}
                {/* Top Article */}
                <div className='flex items-center pt-5 gap-x-3' >
                  <input name="topArticle" onChange={handleChange} checked={values.topArticle} className='h-[30px] w-[30px] ' type="checkbox" />
                  <p>Top Preview</p>
                </div>

                {/* Description */}
                <p className={`${errors.description ? "mt-5" : "mt-5 md:mt-7"} pb-1`} >Description</p>
                <input label="Description" placeholder="Enter match description" name="description" value={values.description} onChange={handleChange("description")} onBlur={handleBlur("description")} type="text" className={`w-full  outline-none border border-[#727272] rounded-[5px] h-[50px] p-5`} />
                {errors.description && <div className="text-red-500 text-[12px] mb-5 mt-3">{errors.description}</div>}

                {/* Source */}
                <p className={`${errors.imageSource ? "mt-5" : "mt-5 md:mt-7"} pb-1`} >Image source title</p>
                <input label="Image source title" placeholder="Enter image source" name="imageSource" value={values.imageSource} onChange={handleChange("imageSource")} onBlur={handleBlur("imageSource")} type="text" className={`w-full ${errors.imageSource ? "" : "mb-5 md:mb-7"} outline-none border border-[#727272] rounded-[5px] h-[50px] p-5`} />
                {errors.imageSource && <div className="text-red-500 text-[12px] mb-5 mt-3">{errors.imageSource}</div>}
                {<>
                  <label className="py-3" htmlFor="uploadResult" >
                      <p className={`pb-1 mt-2`} >Image Upload</p>
                      <div onDragEnter={handleDrag} className={`relative w-full h-[40px] text-truncate md:h-[50px] cursor-pointer ${dragActive ? "border-dashed border" : "border"} rounded-[8px] border-[#727272] mb-2 flex items-center`} >
                          <p className='p-2 md:px-5 text-[#727272] w-full truncate' >{values.image?.length>0 ? values.image?.[0]?.name ? values.image?.[0]?.name : values.image : "Upload image here"}</p>
                          { dragActive && <div className='absolute w-full h-full top-0 left-0 bottom-0 right-0' onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={(e)=>handleDrop(e, "image")}></div> }
                      </div>
                  </label>
                  <input
                      id="uploadResult"
                      type="file"
                      accept="image/*"
                      className="overflow-hidden hidden"
                      onChange={(e)=> setFieldValue("image", e.target.files)}
                  />
                  {errors.image && <div className="text-red-500 text-[12px] my-2">{errors.image}</div>}
                </>}
                <div className='mt-5 md:mt-7' ></div>
                {errors.body && <div className="text-red-500 text-[12px] my-2">{errors.body}</div>}
                <Editor
                    apiKey = {"5afe0oaakdp41dym1nmykw530s45gg4oknpifmzzt0r7tdte"}
                    value={values.body}
                    onEditorChange={(stringifiedHtmlValue) => {
                        setFieldValue("body", stringifiedHtmlValue);
                    }}
                    init={{
                        height: 500,
                        menubar: false,
                        plugins: [
                            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap',
                            'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                            'insertdatetime', 'media', 'table', 'preview', 'help', 'wordcount', 'emoticons'
                        ],
                        toolbar: 'formatselect | bold italic forecolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link | image | undo redo | emoticons | help',
                        automatic_uploads: true,
                        images_upload_handler: async (blobInfo, progress)=> {
                          const formdata = new FormData()
                          formdata.append("image", blobInfo.blob())
                          const {data} = await postApi("upload", formdata);
                          return data?.image_url;

                      }
                    }}
                />
                <div className='flex gap-x-4 md:gap-x-10 justify-between pt-5' >
                    <button type="submit" disabled={isPostLoading || isPostFixtureLoading} onClick={()=> {
                          setFixtureUpdate(true)
                          handleSubmit()
                      }} className='text-white bg-[#003049] font-semibold text-[16px] leading-[24px] rounded-[4px] w-full h-[50px] hover:bg-[#1D5775] mt-5 mb-10 flex justify-center items-center' >{((isPostLoading)) ? <div data-testid="loader2" className="w-6 m-a">
                        <TailSpin color={"#fff"} height="21" width="21" />
                    </div> : "Update Article"}</button>
                </div>
                <div>
                    <button onClick={()=> setPreview(true)} className='text-white bg-[#003049] font-semibold text-[16px] leading-[24px] rounded-[4px] w-full h-[50px] hover:bg-[#1D5775] mb-10 flex justify-center items-center' >Preview</button>
                </div>
            </div>
        </div>}
        {preview && 
            <Modal>
              <div className='w-full h-screen overflow-y-auto' >
                  <div className='flex w-full relative p-5' >
                    <div className='absolute right-5' >
                        <img onClick={()=> setPreview(false)} className='w-[50px] h-[50px] cursor-pointer' src={Cancel} alt="close-nav" />
                    </div>
                </div>
                <ArticleItem preview articleData={{...values, title: `${fixtureData?.teams?.home?.name} vs ${fixtureData?.teams?.away?.name}`, "league": `${fixtureData?.league?.name}`, "image": values.image?.[0]?.name ? URL.createObjectURL(values.image?.[0]) : values.image}} />
              </div>
            </Modal>}
    </GeneralLayout>
  )
}

export default EditCustom