import React from 'react';
import "./modal.css"

const Modal = ({children}) => {
  return (
    <div className="modalBackgroundFull">
      <div className="modalContainerFull">
        {children}
      </div>
    </div>
  )
}

export default Modal