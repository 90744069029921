import React from 'react'
import MiniCard from '../TopPreviews/MiniCard'
import { TailSpin } from "react-loader-spinner";

const LeagueBody = ({ allLeaguePreviews, totalPreviews, setPage, isFetchingLeaguePreviews }) => {
  return (
    allLeaguePreviews?.length > 0 ? <div className='md:py-10 py-5 w-full' >
        <div className='flex w-full flex-col md:flex-row' >
            {allLeaguePreviews?.slice(0,2)?.map((previews, index)=> <div key={previews?._id} className={`${allLeaguePreviews?.slice(0,2)?.length < 2 ? "w-full" : index === 0 ? "md:w-2/3" : "md:w-1/3"} w-full py-3 md:py-0 md:px-2.5`} >
                <MiniCard data={previews} league cardHeight={320} imageHeight={208} textHeight={112} />
            </div>)}
        </div>
        <div className='flex w-full flex-col md:flex-row md:my-7' >
            {allLeaguePreviews?.slice(2,4)?.map((previews, index)=> <div key={previews?._id} className={`${allLeaguePreviews?.slice(2,4)?.length < 2 ? "w-full" : index === 0 ? "md:w-1/3" : "md:w-2/3"} w-full py-3 md:py-0 md:px-2.5`} >
                <MiniCard data={previews} league cardHeight={320} imageHeight={208} textHeight={112} />
            </div>)}
        </div>
        <div className='flex w-full flex-col md:flex-row' >
            {allLeaguePreviews?.slice(4,7)?.map((previews, index)=> <div key={previews?._id} className={`${allLeaguePreviews?.slice(4,7)?.length < 2 ? "w-full" : allLeaguePreviews?.slice(4,7)?.length === 2 ? "md:w-1/2" : index === 0 ? "md:w-1/3" : "md:w-1/3"} w-full py-3 md:py-0 md:px-2.5`} >
                <MiniCard data={previews} league cardHeight={320} imageHeight={208} textHeight={112} />
            </div>)}
        </div>
        <div className='flex w-full flex-col md:flex-row md:flex-wrap ' >
            {allLeaguePreviews?.slice(7)?.map((previews, index)=> <div key={previews?._id} className={`${allLeaguePreviews?.slice(7)?.length < 2 ? "w-full" : allLeaguePreviews?.slice(7)?.length === 2 ? "md:w-1/2" : index === 0 ? "md:w-1/3" : "md:w-1/3"} w-full py-3 md:py-5 md:px-2.5`} >
                <MiniCard data={previews} league cardHeight={320} imageHeight={208} textHeight={112} />
            </div>)}
        </div>
        {isFetchingLeaguePreviews &&
        <div className='flex justify-center items-center w-full h-[20vh] py-5' >
            <TailSpin
                height="60"
                width="60"
                color="#000A0F"
            />
        </div>}
        {totalPreviews>allLeaguePreviews?.length && <div className='flex justify-center pt-5 md:pt-10' >
            <button disabled={isFetchingLeaguePreviews} onClick={()=> setPage((page)=> page + 1)} className='text-white bg-[#003049] font-semibold text-[16px] leading-[24px] rounded-[4px] h-[40px] w-[121px] md:h-[48px] md:w-[129px] hover:bg-[#1D5775]' >Load more</button>
        </div>}
    </div> : <div className='flex justify-center items-center h-[20vh] w-full text-[20px] font-medium' >No previews yet</div>
  )
}

export default LeagueBody