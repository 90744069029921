import React, { useEffect, useMemo } from 'react';
import { footballApi, useGetAllArticlesQuery, useGetUserQuery } from '../../redux/services/Football';
import CarouselPreview from './CarouselPreview';
import SmallPreviews from './SmallPreviews';
import { TailSpin } from "react-loader-spinner";
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

const TopPreviews = () => {
  const { isLoading, data } = useGetAllArticlesQuery();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isSuccess, isLoading: isUserLoading, isError, error } = useGetUserQuery("", { skip: !localStorage.getItem("token") })
  const articlesData = useMemo(()=> data?.articles, [data?.articles]);

  useEffect(() => {
    if(isError){
        if(error?.data?.error==="Token has expired"){
          dispatch(footballApi?.util?.resetApiState());
          localStorage.removeItem("token");
          navigate("/auth/article/login")
        }
    }
  }, [isError, error, navigate, dispatch])
  return (
    <div className='bg-[#FDFDFD] p-5 md:px-10 w-full' >
        <h1 className='font-bold text-[24px] leading-[28.8px] md:text-[32px] md:leadimg-[38.4px] text-[#000A0F] ' >Top Previews</h1>
        <div className='flex smallLaptop:flex-row flex-col w-full' >
            {(isLoading || isUserLoading) ? <div className='flex justify-center items-center h-[80vh] w-full' ><TailSpin
                height="80"
                width="80"
                color="#000A0F"
              /></div> :<>
              {articlesData?.length >0 ? <><CarouselPreview isSuccess={isSuccess} data={articlesData?.slice(0,3)} />
              <SmallPreviews isSuccess={isSuccess} data={articlesData?.slice(3,5)} /></> : <div className='flex justify-center items-center h-[60vh] w-full text-[20px] md:text-[28px] font-semibold text-[#003049]' >No top Previews yet</div>}
            </>}
        </div>
    </div>
  )
}

export default TopPreviews