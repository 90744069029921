import React, { useEffect, useMemo, useState } from 'react';
import GeneralLayout from '../../layouts/GeneralLayout';
import { useLazyGetSearchArticlesQuery } from '../../redux/services/Football';
import { TailSpin } from "react-loader-spinner";
import MiniCard from '../../components/TopPreviews/MiniCard';
import { useSearchParams } from 'react-router-dom';

const Search = () => {
  const [ searchParams, setSearchParams ] = useSearchParams()
  const [ search, setSearch ] = useState(searchParams.get("search") || "");
  const [ searchArticles, { isFetching, data } ] = useLazyGetSearchArticlesQuery();
  const articleData = useMemo(() => data?.articles, [data?.articles]);
  
  useEffect(() => {
    if(search.trim() && search.length > 0){
      searchArticles(search).unwrap();
    }
  }, [])
  

  const handleSearch = () => {
    if(search.trim() && search.length > 0){
      setSearchParams({ search })
      searchArticles(search).unwrap();
    }
  }
  return (
    <div>
        <GeneralLayout >
          <div className='md:py-20 py-10 bg-[#FDFDFD] p-5 md:px-10 w-full min-h-[80vh]' >
            <div className='w-full mx-auto flex justify-center items-center' >
              <div className='w-full' >
                <div className={`w-full flex justify-center ${""}`} >
                  <input value={search} onChange={(e)=> setSearch(e?.target?.value)} className='outline-none md:p-4 py-3 px-4 max-w-[640px] w-full h-[48px] md:h-[56px] rounded-[4px] border-[1.5px] border-[#A6AFB4]'  />
                  <button className={`text-[#F1F3F4] text-[16px] font-semibold leading-[150%] ${search.length>0 ?"bg-[#003049]" : "bg-[#CFD5D8]"} rounded-[4px] h-[48px] md:h-[56px] max-w-[100px] md:max-w-[160px] w-full md:p-4 py-3 px-4 md:ml-6 ml-3`} disabled={isFetching || (!search.trim())} onClick={handleSearch} >Search</button>
                </div>
                <div>
                  {(isFetching) ? <div className='flex justify-center items-center h-[50vh]' ><TailSpin
                    height="80"
                    width="80"
                    color="#000A0F"
                  /></div> :
                  <div>
                    {articleData && <h1 className='text-[24px] md:text-[32px] font-bold leading-[120%] pt-10' >Previews related to <span className='capitalize' >{searchParams.get("search")}</span></h1>}
                    <div className='flex md:flex-row flex-col md:flex-wrap md:pt-10 pt-5 w-full' >
                      {articleData?.length > 0 ? articleData?.map((article)=> <div className='py-3 md:w-1/3 md:px-3' ><MiniCard data={article} league cardHeight={320} imageHeight={208} textHeight={112} /></div>) : !articleData ? null : <div className='flex items-center justify-center w-full h-[60vh]' ><p className='text-[20px] md:text-[28px] font-bold leading-[120%] text-center w-full py-10' >No previews related to <span className='capitalize' >{searchParams.get("search")}</span> </p></div>}
                    </div>
                  </div>}
              </div>
              </div>
            </div>
          </div>
        </GeneralLayout>
    </div>
  )
}

export default Search