import React, { createContext, useState } from 'react'
import AdComponent from '../components/Adcomponent/Adcomponent'
import Footer from '../components/Footer/Footer'
import NavBar from '../components/NavBar/NavBar'
import Subscribe from '../components/Subscribe/Subscribe'

export const MyContext = createContext();
const GeneralLayout = ({children}) => {
  const [page, setPage] = useState(1);
  return (
    <MyContext.Provider value={{ page, setPage }}>
        <NavBar />
        {children}
        <Subscribe />
        <AdComponent />
        <Footer />
    </MyContext.Provider>
  )
}

export default GeneralLayout