import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { footballApi, useGetArticlesByLeagueQuery, useGetLeagueFixturesQuery, useGetLeagueTableQuery, useGetUserQuery } from '../../redux/services/Football';
import GameFixtures from '../Fixtures/GameFixtures';
import TableBody from '../LeagueTable/TableBody';
import TableComp from '../LeagueTable/TableComp';
import NavTab from '../NavTab/NavTab';
import LeagueBody from './LeagueBody';
import LeagueHeader from './LeagueHeader';
import { TailSpin } from "react-loader-spinner";
import { useDispatch } from 'react-redux';
import { MyContext } from '../../layouts/GeneralLayout';

const LeaguePage = () => {
    const [searchParams, setSearchParams ] = useSearchParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const { page, setPage } = useContext(MyContext);
    const [tab, setTab] = useState(searchParams.get("tab") || 'Previews');
    const tabHeaders = ((id==="Friendlies") || (id==="European Matches")) ? [ "Previews", "Fixtures" ] : [ "Previews", "Fixtures", "Results", "Table" ];
    const { isFetching, data } = useGetLeagueFixturesQuery({league: id || 'Premier League', round: "", unregular: ((id==="Friendlies") || (id==="European Matches"))});
    const tableHeaders = [ "Pos", "Team", "P", "W", "D", "L", "GD", "PTS", "FORM" ];
    const { isFetching: isFetchingTable, data: leagueTableData } = useGetLeagueTableQuery(id || 'Premier League');
    const leagueData = useMemo(()=> data?.fixtures, [data?.fixtures]);
    const leagueStandingsData = useMemo(()=> leagueTableData?.standings, [leagueTableData?.standings])
    const { isSuccess, isLoading, isError, error } = useGetUserQuery("", { skip: !localStorage.getItem("token") })
    const { isLoading: isLoadingLeaguePreviews, data: leaguePreviews, isFetching: isFetchingLeaguePreviews } = useGetArticlesByLeagueQuery({id: id || 'Premier League', page, limit: 10})
    const allLeaguePreviews = useMemo(() => leaguePreviews?.articles, [leaguePreviews?.articles]);
    const totalPreviews = useMemo(() => leaguePreviews?.pagination?.total, [leaguePreviews?.pagination?.total]);

    // useEffect(()=> {
    //   if(page > 1){
    //     setPage(1)
    //   }
    // }, [id])
    useEffect(() => {
      if(isError){
          if(error?.data?.error==="Token has expired"){
            dispatch(footballApi?.util?.resetApiState());
            localStorage.removeItem("token");
            navigate("/auth/article/login");
          }
      }
    }, [isError, error, navigate, dispatch])

    const handleNavTab = (navTab, index) => {
        setSearchParams({tab: navTab});
        setTab(navTab)
        const tabElement = document.querySelector(`.leaguepagetab${index}`);
        tabElement.scrollIntoView({ behavior: 'smooth', inline: 'center', block: 'nearest' });
    }
    
  const handleViewTab = () => {
      switch (tab) {
        case "Previews":
          
          return <LeagueBody isFetchingLeaguePreviews={isFetchingLeaguePreviews} totalPreviews={totalPreviews} allLeaguePreviews={allLeaguePreviews} setPage={setPage}  />
      
        case "Fixtures":
        
          return <GameFixtures tab={id} isSuccess={isSuccess} leaguepage fixtures={leagueData} gameWeek={leagueData?.[0]?.gameWeek} />
        
        case "Results":
      
          return <GameFixtures tab={id} isSuccess={isSuccess} result={true} leaguepage fixtures={leagueData} gameWeek={leagueData?.[0]?.gameWeek} />

        case "Table":
    
          return <TableComp headers={tableHeaders} >
              <TableBody isFetching={isFetching} data={leagueStandingsData} />
          </TableComp>
      
        default:
          return <LeagueBody />
      }
  }
  return (
    <div className='bg-[#FDFDFD] p-5 md:px-10 w-full' >
        {(isFetchingTable || isFetching || isLoading || isLoadingLeaguePreviews) ? <div className='flex justify-center items-center h-[80vh]' ><TailSpin
          height="80"
          width="80"
          color="#000A0F"
        /></div> : <><LeagueHeader id={id} league={leagueData?.[0]?.league} />
        <NavTab className={'leaguepagetab'} tab={tab} setTab={handleNavTab} tabHeaders={tabHeaders} />
         {handleViewTab()}</>}
    </div>
  )
}

export default LeaguePage