import React, { useEffect, useMemo } from 'react'
import GeneralLayout from '../../layouts/GeneralLayout';
import { useFormik } from 'formik';
import { ArticleValidations } from '../../helpers/validations';
import { Editor } from '@tinymce/tinymce-react'
import { footballApi, useGetArticleByIdQuery, useGetFixturesByIdQuery, useGetUserQuery, usePostArticleMutation, usePublishSavedArticleMutation, useUpdateArticleMutation } from '../../redux/services/Football';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { TailSpin } from "react-loader-spinner";
import { toast } from "react-toastify";
import { useDispatch } from 'react-redux';
import { postApi } from '../../utils/reqClient';
import Modal from '../../components/Modal/Modal';
import Cancel from "../../assets/Cancel.svg"
import ArticleItem from '../../components/ArticleItem/ArticleItem';

const Home = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const { state } = useLocation();
    const [dragActive, setDragActive] = React.useState(false);
    const [save, setSave] = React.useState(false);
    const [preview, setPreview] = React.useState(false);
    const { isLoading, isError, error } = useGetUserQuery("", { skip: !localStorage.getItem("token") });
    const [ postArticle, { isLoading: isPostLoading, isSuccess } ] = usePostArticleMutation();
    const [ updateArticle, { isLoading: isEditPostLoading, isSuccess: isEditSuccess } ] = useUpdateArticleMutation();
    const [ publishSavedArticle, { isLoading: isPublishLoading, isSuccess: publishSuccess } ]= usePublishSavedArticleMutation()
    const { isFetching, data } = useGetFixturesByIdQuery(id);
    const { isLoading: isArticleLoading, data: articlesData } = useGetArticleByIdQuery(id)
    const articleData = useMemo(() => articlesData?.articles?.[0], [articlesData?.articles])
    // const { isLoading: isEditLoading, data: editData } = useGetArticleByIdQuery(id, { skip: !state?.edit });
    // const articleData = useMemo(() => editData?.articles?.[0], [editData?.articles])
    const teamInfo = useMemo(() => data?.fixtures, [data?.fixtures])
    const navigate = useNavigate();
    // console.log(articlesData)

    // handle drag events
  const handleDrag = function(e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };
  
  // triggers when file is dropped
  const handleDrop = function(e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setFieldValue("image", e.dataTransfer.files)
      // handleFiles();
    }
  };

  useEffect(() => {
    if((articleData && articlesData?.message ==="Article is saved") && !state?.edit){
      formik.setValues(articleData);
    }
  }, [articleData, articlesData, state?.edit]);

    useEffect(() => {
      if(isError){
          if(error?.data?.error==="Token has expired"){
            dispatch(footballApi?.util?.resetApiState());
            localStorage.removeItem("token");
            navigate("/auth/article/login")
          }
      }else if(!localStorage.getItem("token") || isError ){
        navigate("/")
      }
    }, [navigate, isError, error, dispatch])
    
    useEffect(() => {
      if(publishSuccess){
        // console.log("Done");
        window.location.href=`${process.env.PUBLIC_URL}/article/admin/${id}/${teamInfo?.teams?.home?.name} vs ${teamInfo?.teams?.away?.name}`
      //  return  navigate(`/article/admin/${id}`)
      }else if((isSuccess || isEditSuccess) && !save){
        // console.log("Publish Done Directly")
        window.location.href=`${process.env.PUBLIC_URL}/article/admin/${id}/${teamInfo?.teams?.home?.name} vs ${teamInfo?.teams?.away?.name}`
        // return navigate(`/article/admin/${id}`)
      }
      else if((isSuccess || isEditSuccess) && save){
        toast.success("Article Saved Successfully", {
            position: toast.POSITION.TOP_CENTER
        })
      }
    }, [isSuccess, id, navigate, isEditSuccess, save, publishSuccess]);
    
    const formik = useFormik({
        initialValues: {
          description: state?.edit ? state?.articleData?.description : '',
          body: state?.edit ? state?.articleData?.body : '',
          image: state?.edit ? state?.articleData?.image : "",
          imageSource: state?.edit ? state?.articleData?.imageSource : "",
          topArticle: state?.edit ? state?.articleData?.topArticle : false
        },
        validationSchema: ArticleValidations,
        onSubmit: async values => {
            let formdata = new FormData();
            formdata.append("description", values.description);
            formdata.append("topArticle", values.topArticle);
            formdata.append("body", values.body);
            formdata.append("imageSource", values.imageSource);
            formdata.append("league", `${teamInfo?.league?.name}`);
            formdata.append("image", values.image?.[0]?.name ? values.image?.[0] : values.image);
            formdata.append("title", `${teamInfo?.teams?.home?.name} vs ${teamInfo?.teams?.away?.name}`);
            formdata.append("tags", `${teamInfo?.league?.name}`);
            formdata.append("tags", `${teamInfo?.teams?.home?.name}`);
            formdata.append("tags", `${teamInfo?.teams?.away?.name}`)
            formdata.append("custom", false);
            state?.edit ? updateArticle({id: state?.articleData?._id, formdata}) : ((articlesData?.message ==="Article is saved")&& !save) ? publishSavedArticle({id: articleData?._id, formdata}) : postArticle({id, formdata, save});
        }
      });

    const {
        values,
        handleChange,
        handleSubmit,
        setFieldValue,
        handleBlur,
        errors,
    } = formik;
  return (
    <div>
         <GeneralLayout>
            {(isLoading || isFetching || isArticleLoading) ? <div className='flex justify-center items-center h-[60vh]' ><TailSpin
                height="80"
                width="80"
                color="#000A0F"
                /></div> : <div className='md:p-10 p-5 max-w-[850px] mx-auto' >
                <h1 className='font-bold md:text-[3rem] text-[1.5rem] md:leading-[120%] leading-[120%] text-[#000A0F] md:text-[#000A0F]'>{`${teamInfo?.teams?.home?.name} vs ${teamInfo?.teams?.away?.name}`}</h1>

                {/* Top Article */}
                <div className='flex items-center pt-5 gap-x-3' >
                  <input name="topArticle" onChange={handleChange} checked={values.topArticle} className='h-[30px] w-[30px] ' type="checkbox" />
                  <p>Top Preview</p>
                </div>

                {/* Description */}
                <p className={`${errors.description ? "mt-5" : "mt-5 md:mt-7"} pb-1`} >Description</p>
                <input label="Description" placeholder="Enter match description" name="description" value={values.description} onChange={handleChange("description")} onBlur={handleBlur("description")} type="text" className={`w-full  outline-none border border-[#727272] rounded-[5px] h-[50px] p-5`} />
                {errors.description && <div className="text-red-500 text-[12px] mb-5 mt-3">{errors.description}</div>}

                {/* Source */}
                <p className={`${errors.imageSource ? "mt-5" : "mt-5 md:mt-7"} pb-1`} >Image source title</p>
                <input label="Image source title" placeholder="Enter image source" name="imageSource" value={values.imageSource} onChange={handleChange("imageSource")} onBlur={handleBlur("imageSource")} type="text" className={`w-full ${errors.imageSource ? "" : "mb-5 md:mb-7"} outline-none border border-[#727272] rounded-[5px] h-[50px] p-5`} />
                {errors.imageSource && <div className="text-red-500 text-[12px] mb-5 mt-3">{errors.imageSource}</div>}
                {<>
                  <label className="py-3" htmlFor="uploadResult" >
                      <p className={`pb-1 mt-2`} >Image Upload</p>
                      <div onDragEnter={handleDrag} className={`relative w-full h-[40px] text-truncate md:h-[50px] cursor-pointer ${dragActive ? "border-dashed border" : "border"} rounded-[8px] border-[#727272] mb-2 flex items-center`} >
                          <p className='p-2 md:px-5 text-[#727272] w-full truncate' >{values.image?.length>0 ? values.image?.[0]?.name ? values.image?.[0]?.name : values.image : "Upload image here"}</p>
                          { dragActive && <div className='absolute w-full h-full top-0 left-0 bottom-0 right-0' onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div> }
                      </div>
                  </label>
                  <input
                      id="uploadResult"
                      type="file"
                      accept="image/*"
                      className="overflow-hidden hidden"
                      onChange={(e)=> setFieldValue("image", e.target.files)}
                  />
                  {errors.image && <div className="text-red-500 text-[12px] my-2">{errors.image}</div>}
                </>}
                <div className='mt-5 md:mt-7' ></div>
                {errors.body && <div className="text-red-500 text-[12px] my-2">{errors.body}</div>}
                <Editor
                        apiKey = {"5afe0oaakdp41dym1nmykw530s45gg4oknpifmzzt0r7tdte"}
                        value={values.body}
                        onEditorChange={(stringifiedHtmlValue) => {
                            setFieldValue("body", stringifiedHtmlValue);
                        }}
                        init={{
                            height: 500,
                            menubar: false,
                            plugins: [
                                'advlist', 'autolink', 'lists', 'link', 'image', 'charmap',
                                'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                'insertdatetime', 'media', 'table', 'preview', 'help', 'wordcount', 'emoticons'
                            ],
                            toolbar: 'formatselect | bold italic forecolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link | image | undo redo | emoticons | help',
                            automatic_uploads: true,
                            images_upload_handler: async (blobInfo, progress)=> {
                                const formdata = new FormData()
                                formdata.append("image", blobInfo.blob())
                                const {data} = await postApi("upload", formdata);
                                return data?.image_url;

                            }
                        }}
                    />
                    <div className='flex gap-x-4 md:gap-x-10 justify-between pt-5' >
                        <button type="submit" disabled={isPostLoading || isEditPostLoading || isPublishLoading} onClick={()=> {
                          setSave(false)
                          handleSubmit()
                          }} className='text-white bg-[#003049] font-semibold text-[16px] leading-[24px] rounded-[4px] w-full h-[50px] hover:bg-[#1D5775] mt-5 mb-10 flex justify-center items-center' >{((isPostLoading || isEditPostLoading || isPublishLoading ) && !save) ? <div data-testid="loader2" className="w-6 m-a">
                            <TailSpin color={"#fff"} height="21" width="21" />
                        </div> : "Post Article"}</button>
                        {!state?.edit && <button type="submit" disabled={isPostLoading || isEditPostLoading || isPublishLoading} onClick={()=> {
                          setSave(true)
                          handleSubmit()
                          }} className='text-white bg-[#003049] font-semibold text-[16px] leading-[24px] rounded-[4px] w-full h-[50px] hover:bg-[#1D5775] mt-5 mb-10 flex justify-center items-center' >{((isPostLoading || isEditPostLoading) && save ) ? <div data-testid="loader2" className="w-6 m-a">
                            <TailSpin color={"#fff"} height="21" width="21" />
                        </div> : "Save Article"}</button>}
                    </div>
                    <div>
                      <button onClick={()=> setPreview(true)} className='text-white bg-[#003049] font-semibold text-[16px] leading-[24px] rounded-[4px] w-full h-[50px] hover:bg-[#1D5775] mb-10 flex justify-center items-center' >Preview</button>
                    </div>
            </div>}
            {preview && 
            <Modal>
              <div className='w-full h-screen overflow-y-auto' >
                  <div className='flex w-full relative p-5' >
                    <div className='absolute right-5' >
                        <img onClick={()=> setPreview(false)} className='w-[50px] h-[50px] cursor-pointer' src={Cancel} alt="close-nav" />
                    </div>
                </div>
                <ArticleItem preview articleData={{...values, title: `${teamInfo?.teams?.home?.name} Vs ${teamInfo?.teams?.away?.name}`, "league": `${teamInfo?.league?.name}`, "image": values.image?.[0]?.name ? URL.createObjectURL(values.image?.[0]) : values.image}} />
              </div>
            </Modal>}
        </GeneralLayout>
    </div>
  )
}

export default Home