import * as Yup from 'yup';

export const LoginValidations = () => {
    return Yup.object().shape({
      email: Yup.string()
        .email('Invalid email, please provide a valid email.')
        .required('Email is required'),
      password: Yup.string()
        .required('Password is required'),
    });
}

export const EmailValidations = () => {
  return Yup.object().shape({
    email: Yup.string()
      .email('Invalid email, please provide a valid email.')
      .required('Email is required'),
  });
}

export const ResetPasswordValidations = () => {
  return Yup.object().shape({
    password: Yup.string().min(8, "Password must be atleast 8 characters").required("Please input new password"),
    confirmPassword: Yup.string().required("Password is required").oneOf([Yup.ref('password'), null], 'Passwords must match'),
    otp: Yup.string().min(6, "OTP must be 6 characters").max(6, "OTP must be 6 characters").required("Please input otp"),
  });
}

export const ArticleValidations = () => {
  return Yup.object().shape({
    description: Yup.string()
      .required('Description is required'),
    body: Yup.string()
      .required('Content is required'),
    image: Yup.mixed().required('Image is required').test('fileType', 'Invalid file format', (value) => {
      if(typeof value === 'string'){
        return true;
      }else if (value && value.length) {
        const file = value[0];
        const validTypes = ['image/jpeg', 'image/png', 'image/gif'];
        return validTypes.includes(file.type);
      }
      return false; // Disallow empty value
    }),
    imageSource: Yup.string()
    .required('Image source is required'),
  });
}

export const FixtureValidations = () => {
  return Yup.object().shape({
    homeTeam: Yup.string()
      .required('Home Team is required'),
    awayTeam: Yup.string()
      .required('Away Team is required'),
    homeTeamLogo: Yup.mixed().required('Home Team Logo is required').test('fileType', 'Invalid file format', (value) => {
      if(typeof value === 'string'){
        return true;
      }else if (value && value.length) {
        const file = value[0];
        const validTypes = ['image/jpeg', 'image/png', 'image/gif'];
        return validTypes.includes(file.type);
      }
      return false; // Disallow empty value
    }),
    awayTeamLogo: Yup.mixed().required('Away Team Logo is required').test('fileType', 'Invalid file format', (value) => {
      if(typeof value === 'string'){
        return true;
      }else if (value && value.length) {
        const file = value[0];
        const validTypes = ['image/jpeg', 'image/png', 'image/gif'];
        return validTypes.includes(file.type);
      }
      return false; // Disallow empty value
    }),
    dateAndTime: Yup.string()
      .required('Date and Time is required'),
    league: Yup.string()
      .required('Category is required'),
  });
}

export const ArticleWthValidations = () => {
  return Yup.object().shape({
    description: Yup.string()
      .required('Description is required'),
    body: Yup.string()
      .required('Content is required'),
  });
}