import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import GeneralLayout from '../../layouts/GeneralLayout'
import { useGetRelatedArticlesQuery } from '../../redux/services/Football';
import { TailSpin } from "react-loader-spinner";
import MiniCard from '../../components/TopPreviews/MiniCard';

const Tag = () => {
  const { id } = useParams();
  const { isLoading, data } = useGetRelatedArticlesQuery({id, page: 1, limit: 10});
  const articleData = useMemo(() =>  data?.articles, [data?.articles])
  return (
    <div>
      <GeneralLayout >
        <div className='md:pt-20 pt-10 bg-[#FDFDFD] p-5 md:px-10 w-full' >
          <h1 className='text-[24px] md:text-[32px] font-bold leading-[120%]' >Previews related to <span className='capitalize' >{id}</span></h1>
          <div>
              {(isLoading) ? <div className='flex justify-center items-center h-[80vh]' ><TailSpin
                height="80"
                width="80"
                color="#000A0F"
              /></div> : 
              <div className='flex md:flex-row flex-col md:flex-wrap md:pt-10 pt-5 w-full' >
                {articleData?.length > 0 ? articleData?.map((article)=> <div className='py-3 md:w-1/3 md:px-3' ><MiniCard data={article} league cardHeight={320} imageHeight={208} textHeight={112} /></div>) : <div className='flex items-center justify-center w-full h-[60vh]' ><p className='text-[20px] md:text-[28px] font-bold leading-[120%] text-center w-full py-10' >No previews related to <span className='capitalize' >{id}</span> </p></div>}
              </div>}
          </div>
        </div>
      </GeneralLayout>
    </div>
  )
}

export default Tag