import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// Define your API endpoints here
export const footballApi = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({ baseUrl: "https://ill-ruby-shark-fez.cyclic.app" }),
  endpoints: (builder) => ({
    // Define your endpoints here
    getLeagueTable: builder.query({
      query: (league) => `standings/${league}`,
    }),
    getAllArticles: builder.query({
      query: () => `toprated/articles`,
    }),
    getAllArticlesByAdmin: builder.query({
      query:(page) => ({
        url: `articles?page=${page}&limit=${10}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      }),
    }),
    getArticlesByLeague: builder.query({
      query: ({id, page, limit}) => `article/${id}?page=${page}&limit=${limit}`,
      serializeQueryArgs: ({ limit, id, page }) => {
        return `id=${id}&page=${page}&limit=${limit}`;
      },
      merge: (currentCache, newItems) => {
        // console.log(newItems?.message === "Article not found");
        // Object.assign(currentCache, newItems);
        if(newItems?.message === "Article not found"){
          // Object.assign(currentCache, newItems)
          return newItems
        }else if(currentCache?.articles && currentCache?.articles?.length > 0){
            if(currentCache?.articles?.[0]?.league !== newItems?.articles?.[0]?.league){
              Object.assign(currentCache, newItems)
            }else if ((currentCache?.articles?.[0]?.league === newItems?.articles?.[0]?.league) && (currentCache?.pagination?.page !== newItems?.pagination?.page)){
              currentCache?.articles?.push(...newItems?.articles)
            }else {
              Object.assign(currentCache, newItems)
            }
        }else if(!currentCache?.articles && newItems?.articles?.length > 0){
          Object.assign(currentCache, newItems)
        }
      },
      forceRefetch({ currentArg, previousArg }) {
          return currentArg?.page !== previousArg?.page || currentArg?.id !== previousArg?.id
      },
    }),
    getUser: builder.query({
      query: () => ({
        url: `auth/me`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      }),
      cacheTime: 86400000,
    }),
    getLeagueFixtures: builder.query({
      query: ({league, round, unregular}) => `fixtures/${league?.toLowerCase()}/${unregular ? "" : round ? round : "current"}`,
    }),
    getFixturesById: builder.query({
      query: (id) => ({
        url: `fixture/${id}/`,
        // headers: {
        //   Authorization: `Bearer ${localStorage.getItem("token")}`
        // }
      }),
    }),
    getRelatedArticles: builder.query({
      query: ({id, page, limit}) => ({
        url: `articles/tag/${id}?page=${page}&limit=${limit}`,
        // headers: {
        //   Authorization: `Bearer ${localStorage.getItem("token")}`
        // }
      }),
    }),
    getSearchArticles: builder.query({
      query: (id) => ({
        url: `search/articles/${id}`,
        // headers: {
        //   Authorization: `Bearer ${localStorage.getItem("token")}`
        // }
      }),
    }),
    getArticleById: builder.query({
      query: (id) => ({
        url: `articles/fixture/${id}/`,
        // headers: {
        //   Authorization: `Bearer ${localStorage.getItem("token")}`
        // }
      }),
      providesTags: ["Article"]
    }),
    getFixtureById: builder.query({
      query: (id) => ({
        url: `fixture/${id}/`,
        // headers: {
        //   Authorization: `Bearer ${localStorage.getItem("token")}`
        // }
      }),
      providesTags: ["Fixture"]
    }),
    login: builder.mutation({
        query: (body)=> ({
            method: "POST",
            body,
            url: "auth/login",
        })
    }),
    forgotPassword: builder.mutation({
      query: (body)=> ({
          method: "POST",
          body,
          url: "auth/forgotpassword",
      })
    }),
    resetPassword: builder.mutation({
      query: (body)=> ({
          method: "PUT",
          body,
          url: "auth/resetpassword",
      })
    }),
    postArticle: builder.mutation({
      query: ({id, formdata, save})=> ({
          method: "POST",
          body: formdata,
          url: `articles/${id}/?save=${save}`,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
      }),
      // async onQueryStarted({ id, save }, { dispatch, queryFulfilled }) {
      //     try {
      //       const { data } = await queryFulfilled
      //       const patchResult = dispatch(
      //         footballApi?.util.updateQueryData("getArticleById", id, save, (draft) => {
      //           console.log(draft, "Omoo");
      //           Object.assign(draft, { message: "Article is published", articles: [{...data?.newArticle}] });
      //         })
      //       )
      //     } catch (error) {
            
      //     }
      // }
      invalidatesTags: ["Article"]
    }),
    postFixture: builder.mutation({
      query: ({formdata})=> ({
          method: "POST",
          body: formdata,
          url: `fixtures`,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
      }),
    }),
    publishSavedArticle: builder.mutation({
      query: ({id, formdata})=> ({
          method: "PUT",
          body: formdata,
          url: `publish/articles/${id}`,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
      }),
      invalidatesTags: ["Article"]
    }),
    updateArticle: builder.mutation({
      query: ({id, formdata})=> ({
          method: "PUT",
          body: formdata,
          url: `articles/${id}`,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
      }),
      invalidatesTags: ["Article"]
    }),
    updateFixture: builder.mutation({
      query: ({id, formdata})=> ({
          method: "PUT",
          body: formdata,
          url: `fixtures/${id}`,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
      }),
      invalidatesTags: ["Article"]
    }),
    subscribe: builder.mutation({
      query: ({ email })=> ({
        method: "POST",
        body: {email},
        url: `mailchimp/subscribe`,
    }),
    })
  }),
});

// Export the generated hooks for each endpoint
export const { useGetAllArticlesByAdminQuery, useForgotPasswordMutation, useResetPasswordMutation, useUpdateFixtureMutation, useGetFixtureByIdQuery, usePostFixtureMutation, useSubscribeMutation, usePublishSavedArticleMutation, useLazyGetSearchArticlesQuery, useGetArticlesByLeagueQuery, useGetRelatedArticlesQuery, useUpdateArticleMutation, useGetAllArticlesQuery, useGetArticleByIdQuery, useGetLeagueTableQuery, useGetLeagueFixturesQuery, useLoginMutation, useGetUserQuery, useGetFixturesByIdQuery, usePostArticleMutation } = footballApi;